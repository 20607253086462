import styled from 'styled-components'
import { currentTheme, testTheme, theme } from '../../Styles'

export const MobileSidebar = styled.div`
    z-index: 15001;
    position: fixed;
    left: ${props => props.isOpen == false ? "-190vw" : "0px" };
    height: 100vh;
    width: 90vw;
    background-color: ${theme.mainDarker};
    box-shadow: 2px 2px 4px 0px ${theme.secondaryHover};
    transition: left 0.5s;

    @media (min-width: 901px) {
       display: none;
   }
`

export const LargeScreenNavDropDown = styled.div`
    z-index: 15001;
    position: fixed;
    right: ${props => props.isOpen == false ? "-190vw" : "30px" };
    top: ${props => props.isOpen == false ? "-180vh" : "90px" };
    height: 80vh;
    width: 250px;
    background-color: ${theme.mainDarker};
    border-radius: 10px;
    box-shadow: -2px 2px 4px 0px ${theme.secondaryHover};
    transition: top 0.5s;

    @media (max-width: 900px) {
       display: none;
   }
`

export const SidebarOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9000;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    background-color: #1c1c1c;
    opacity: 80%; 
    @media (min-width: 901px) {
       //display: none;
   }
`

export const SidebarLinksGap = styled.div`
    position: relative;
    width: 100%;
    height: 80px;
`

export const CloseSidebarButton = styled.button`
    position: absolute;
    top: 25px;
    right: 25px;
    color: white;
    font-size: 24px;
    font-weight: 900;
`

export const SidebarLinkContainer = styled.button`
    //display: inline-block;
    padding-left: 35px;
    vertical-align: middle;
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    text-align: left;
    font-weight: ${props => props.navLocation == props.currentNavbarLocation ? 900 : 200 };
    color: ${props => props.navLocation == props.currentNavbarLocation ? theme.secondary : theme.textSecondary};
    //border-bottom: ${props => props.navLocation == props.currentNavbarLocation && "3px solid " + theme.secondary};
    margin-top: 5px;

   cursor: default;


`
export const SidebarScrollWrapper = styled.div`
    height: 80%;
    overflow-y: scroll;
    padding-bottom: 5em;
`