import React, { useEffect, useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { UALProvider } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { TrustedByTitle, CallToAction, CallToActionCont, StatsCont, SingleStat, SingleStatHeader, SingleStatDetail, FeaturesCont, SingleFeature, TrustedBySmall, BenefitsCont, SingleBenefitPic, SingleBenefitText, SingleBenefitTitle, SingleBenefitDescription, SocialProofCont, SocialProofTitle, SocialProofLogoCont, LastCallToActionCont, LastCallToActionTitle, HomepageFAQCont, HomepageFAQTitle, HomepageFAQDescription, HomepageQuestion, HomepageQuestionPlusSign, HomepageFAQAnswer, FAQHref, MainContainer } from '../components/LeftDiv';
import NumberFormat from 'react-number-format';

import { anchor, wax, myChain, atomicApiList, hyperionApiList, mainnetApiList, mainnetAtomicApiList, currentWebsiteURL, currentAtomicApiList, currentApiList, localServerURL, getStatsEndpoint, wombat } from '../data/config';
import { HomePageCont, HeroLeftSide, HeroRightSide, HeroLargeText, HeroMediumText, CallToActionHrefHomepage, CallToActionContent, HeroAd, HeroAdImage, HeroAdMessage, WojakCallToAction, SocialProofSingleLogo } from '../data/css/HomePageStyles';
import { MilestoneContainer, MilestoneDescription, NewListingBody, NewListingCont, TeamMember, testTheme, theme } from '../Styles';


const Code = () => {


  return (
  <div id="seo">
    <Helmet>
    <title>Code Of Conduct - WaxDAO BP</title>
    <meta name="description" content="WaxDAO's code of conduct as a block producer'" />
    <link rel="canonical" href={`${currentWebsiteURL}/code-of-conduct`} />
    </Helmet>

  

    <NewListingCont>
        <NewListingBody>
            <MilestoneContainer>
                <h2>Code Of Conduct</h2>
                <br/>
                <MilestoneDescription>
                WaxDAO BP is determined to improve the WAX ecosystem by providing useful tools and growing the community. We believe that WAX has a very bright future - but in order to reach its full potential, it needs a comprehensive suite of tools that developers, creators and users can leverage. Therefore, our mission is to employ a "build it and they will come" approach by laying the foundation that others can continue to build on. Our tools - in addition to our infrastructure - will serve to make the network a much better place.
                </MilestoneDescription>
            </MilestoneContainer>       
                                                             

        </NewListingBody>
    </NewListingCont>




    <br/><br/><br/>
    </div>
  )
}

export default Code