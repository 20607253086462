import React from 'react'
import { NotFoundCont, WalletCallToActionHref } from './LeftDiv'
import { currentWebsiteURL } from '../data/config';

const NotFound = () => {

  return (
    <NotFoundCont>This page could not be found
        <br/>
        <WalletCallToActionHref href={currentWebsiteURL}>
            Take Me Home!
        </WalletCallToActionHref>



    </NotFoundCont>
  )
}

export default NotFound