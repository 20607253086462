import React, { useEffect, useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { UALProvider } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { TrustedByTitle, CallToAction, CallToActionCont, StatsCont, SingleStat, SingleStatHeader, SingleStatDetail, FeaturesCont, SingleFeature, TrustedBySmall, BenefitsCont, SingleBenefitPic, SingleBenefitText, SingleBenefitTitle, SingleBenefitDescription, SocialProofCont, SocialProofTitle, SocialProofLogoCont, LastCallToActionCont, LastCallToActionTitle, HomepageFAQCont, HomepageFAQTitle, HomepageFAQDescription, HomepageQuestion, HomepageQuestionPlusSign, HomepageFAQAnswer, FAQHref } from '../components/LeftDiv';
import NumberFormat from 'react-number-format';

import { anchor, wax, myChain, atomicApiList, hyperionApiList, mainnetApiList, mainnetAtomicApiList, currentWebsiteURL, currentAtomicApiList, currentApiList, localServerURL, getStatsEndpoint, wombat } from '../data/config';
import { HomePageCont, HeroLeftSide, HeroRightSide, HeroLargeText, HeroMediumText, CallToActionHrefHomepage, CallToActionContent, HeroAd, HeroAdImage, HeroAdMessage, WojakCallToAction, SocialProofSingleLogo } from '../data/css/HomePageStyles';
import { testTheme, theme } from '../Styles';



const HomePage = () => {


  return (
  <div id="seo">
    <Helmet>
    <title>Home - WaxDAO BP</title>
    <meta name="description" content="WaxDAO BP is committed to serving the WAX blockchain" />
    <link rel="canonical" href={currentWebsiteURL} />
    </Helmet>

  
    <HomePageCont>
      <HeroLeftSide>
        <HeroLargeText>
        <span style={{color: theme.secondary}}>WaxDAO BP</span>
        </HeroLargeText>
        <HeroMediumText>
        Building tools and products for the WAX community.
        </HeroMediumText>



      </HeroLeftSide>



    </HomePageCont>

    <br/><br/><br/>
    </div>
  )
}

export default HomePage