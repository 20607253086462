import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Link, Navigate } from 'react-router-dom';



import { Footer, Navbar, ThemeSettings } from './components';
import { HomePage } from './pages';

import { Body, MainWrapper } from './Styles';

import { useStateContext } from './contexts/ContextProvider';

import './App.css';

import NotFound from './components/NotFound';
import LoadingPage from './components/LoadingPage';
import Tools from './pages/Tools';
import Team from './pages/Team';
import Code from './pages/Code';
import Infra from './pages/Infra';

const reload = () => window.location.reload();

const App = () => {



    const { activeMenu, themeSettings, currentMode, currentUser } = useStateContext();

    return (
    <div style={{fontFamily: "Roboto"}}>

        <BrowserRouter>
            <MainWrapper>

            <div className={`min-h-screen w-full flex-2`}>

                    <Navbar />
                    <Body>
              
            <div>
                {themeSettings && <ThemeSettings />}

                <Suspense fallback={<LoadingPage />}>
                <Routes>

                    {/* Home */}

                    <Route path="/" element={<HomePage />} />

                    <Route path="/tools" element={<Tools />} />
                    <Route path="/infra" element={<Infra />} />
                    <Route path="/team" element={<Team />} />
                    <Route path="/code-of-conduct" element={<Code />} />

                    {/* 404 NOT FOUND CATCHALL */}

                    <Route path="*" element={<NotFound />} />

                </Routes>
                </Suspense>
            </div>
            </Body>
            </div>

            </MainWrapper>       
        </BrowserRouter>
    </div>
  )
}

export default App