import React, { useEffect, useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { UALProvider } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { TrustedByTitle, CallToAction, CallToActionCont, StatsCont, SingleStat, SingleStatHeader, SingleStatDetail, FeaturesCont, SingleFeature, TrustedBySmall, BenefitsCont, SingleBenefitPic, SingleBenefitText, SingleBenefitTitle, SingleBenefitDescription, SocialProofCont, SocialProofTitle, SocialProofLogoCont, LastCallToActionCont, LastCallToActionTitle, HomepageFAQCont, HomepageFAQTitle, HomepageFAQDescription, HomepageQuestion, HomepageQuestionPlusSign, HomepageFAQAnswer, FAQHref, MainContainer } from '../components/LeftDiv';
import NumberFormat from 'react-number-format';

import { anchor, wax, myChain, atomicApiList, hyperionApiList, mainnetApiList, mainnetAtomicApiList, currentWebsiteURL, currentAtomicApiList, currentApiList, localServerURL, getStatsEndpoint, wombat } from '../data/config';
import { HomePageCont, HeroLeftSide, HeroRightSide, HeroLargeText, HeroMediumText, CallToActionHrefHomepage, CallToActionContent, HeroAd, HeroAdImage, HeroAdMessage, WojakCallToAction, SocialProofSingleLogo } from '../data/css/HomePageStyles';
import { MilestoneContainer, MilestoneDescription, NewListingBody, NewListingCont, testTheme, theme } from '../Styles';



const Tools = () => {


  return (
  <div id="seo">
    <Helmet>
    <title>Tools - WaxDAO BP</title>
    <meta name="description" content="A list of tools that WaxDAO has created on WAX blockchain" />
    <link rel="canonical" href={`${currentWebsiteURL}/tools`} />
    </Helmet>

  

    <NewListingCont>
        <NewListingBody>
            <MilestoneContainer>
                
                <h2><a href="https://waxdao.io/farms" target="none">NFT Farms</a></h2>
                <br/>
                <MilestoneDescription>
                    NFT staking farms are our flagship product. They allow any creator on WAX to
                    set up a staking system for their NFT project within minutes. To date, almost 600
                    farms have been created.
                </MilestoneDescription>
            </MilestoneContainer>

            <MilestoneContainer>
                <h2><a href="https://waxdao.io/pools" target="none">Token Farms</a></h2>
                <br/>
                <MilestoneDescription>
                    Just like our NFT farms, our token farms allow creators to set up a staking system for any WAX token.
                    There are currently over 85 token farms that have been created on WaxDAO.
                </MilestoneDescription>
            </MilestoneContainer>  

            <MilestoneContainer>
                <h2><a href="https://waxdao.io/blends" target="none">Super Blender</a></h2>
                <br/>
                <MilestoneDescription>
                    Our super blender allows creators to set up complex blends, with fungible or non-fungible tokens as ingredients.
                    Blend results can be mint-on-demand NFTs, fungible tokens, or preminted NFTs drawn randomly from a pool. To date,
                    almost 1,200 blends have been created on our platform.
                </MilestoneDescription>
            </MilestoneContainer> 

            <MilestoneContainer>
                <h2><a href="https://waxdao.io/drops" target="none">NFT Drops</a></h2>
                <br/>
                <MilestoneDescription>
                    Any creator on WAX can use our platform to create NFT drops. They can accept any token that they like as payment for the drop.
                    To date, 2,000 drops have been created using WaxDAO.
                </MilestoneDescription>
            </MilestoneContainer>             

            <MilestoneContainer>
                <h2>Escrow</h2>
                <br/>
                <MilestoneDescription>
                    Our open-source escrow contract (funded by WaxLabs) allows users to set up slow-drip
                    payments to WAX accounts, acting as an automated, trustless payroll system.
                </MilestoneDescription>
            </MilestoneContainer>   

            <MilestoneContainer>
                <h2><a href="https://waxdao.io/token-creator" target="none">Token Creator</a></h2>
                <br/>
                <MilestoneDescription>
                    Our token creator allows project owners to deploy our open-source token contract to their account.
                    The contract is the standard eosio.token contract, with an added "burn" function - allowing the contract
                    owner to permanently remove tokens from the supply. Over 125 people have used our token creator.
                </MilestoneDescription>
            </MilestoneContainer>  

            <MilestoneContainer>
                <h2><a href="https://waxdao.io/tokens" target="none">Market Cap Tracker</a></h2>
                <br/>
                <MilestoneDescription>
                    We built a web-app that tracks the prices, market info and other helpful data about tokens on WAX.
                    Over 800 tokens are currently being tracked on the app.
                </MilestoneDescription>
            </MilestoneContainer>    

            <MilestoneContainer>
                <h2><a href="https://waxdao.io/create-premint" target="none">Pre-Minted Pack Creator</a></h2>
                <br/>
                <MilestoneDescription>
                    Anyone can use our pre-minted pack creator, free of charge, to create packs of pre-minted NFTs.
                    375 pools of pre-minted NFTs have been created by our users.
                </MilestoneDescription>
            </MilestoneContainer>  

            <MilestoneContainer>
                <h2><a href="https://waxdao.io/daos" target="none">DAO Creator</a></h2>
                <br/>
                <MilestoneDescription>
                    We believe strongly in blockchain governance, so we created a tool that allows project owners to
                    create their own DAO. They can use any WAX token that they like as the governance asset.
                    24 DAOs have been created on our platform.
                </MilestoneDescription>
            </MilestoneContainer>                                                                 

        </NewListingBody>
    </NewListingCont>




    <br/><br/><br/>
    </div>
  )
}

export default Tools