import React, { useEffect, useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { UALProvider } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { TrustedByTitle, CallToAction, CallToActionCont, StatsCont, SingleStat, SingleStatHeader, SingleStatDetail, FeaturesCont, SingleFeature, TrustedBySmall, BenefitsCont, SingleBenefitPic, SingleBenefitText, SingleBenefitTitle, SingleBenefitDescription, SocialProofCont, SocialProofTitle, SocialProofLogoCont, LastCallToActionCont, LastCallToActionTitle, HomepageFAQCont, HomepageFAQTitle, HomepageFAQDescription, HomepageQuestion, HomepageQuestionPlusSign, HomepageFAQAnswer, FAQHref, MainContainer } from '../components/LeftDiv';
import NumberFormat from 'react-number-format';

import { anchor, wax, myChain, atomicApiList, hyperionApiList, mainnetApiList, mainnetAtomicApiList, currentWebsiteURL, currentAtomicApiList, currentApiList, localServerURL, getStatsEndpoint, wombat } from '../data/config';
import { HomePageCont, HeroLeftSide, HeroRightSide, HeroLargeText, HeroMediumText, CallToActionHrefHomepage, CallToActionContent, HeroAd, HeroAdImage, HeroAdMessage, WojakCallToAction, SocialProofSingleLogo } from '../data/css/HomePageStyles';
import { MilestoneContainer, MilestoneDescription, NewListingBody, NewListingCont, TeamMember, testTheme, theme } from '../Styles';
import mike_team from "../data/mike_team.png";


const Team = () => {


  return (
  <div id="seo">
    <Helmet>
    <title>Team - WaxDAO</title>
    <meta name="description" content="A list of WaxDAO BP team members" />
    <link rel="canonical" href={`${currentWebsiteURL}/team`} />
    </Helmet>

  

    <NewListingCont>
        <NewListingBody>

           <TeamMember>
            <img src={mike_team} style={{width: "200px", maxWidth: "200px", marginLeft: "auto", marginRight: "auto", borderRadius: "5px"}} />
            <br/>
            <h3>Mike - Founder</h3>  
            <br/>          
            <MilestoneDescription>
            Mike has been in the crypto space for almost a decade, mostly as a trader/investor. In June of 2022, he decided to build waxdao.io, his first crypto project. What started out as a fun little experiment ended up growing into a massive suite of tools, with over 2,000 unique users each month. WaxDAO is now Mike's full time job. Mike strongly believes that WAX/Antelope is a superior technology when compared to other blockchains, which is why he chose WAX as the home for his project. 
            </MilestoneDescription>
            </TeamMember>                                                              

        </NewListingBody>
    </NewListingCont>




    <br/><br/><br/>
    </div>
  )
}

export default Team