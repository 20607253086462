import styled from 'styled-components'
import { currentTheme, testTheme, theme } from '../../Styles'

export const FooterCont = styled.div`
    display: flex;
    width: 100%;
    max-width: 100%;
    max-height: auto;
    margin-left: 0px; 
    margin-right: 0px;
    //background-color: ${testTheme.mainDark};
    background: #1F2A44;
    background: linear-gradient(0deg, #1F2A44 62%, #151f36 90%);
    
    
    //border-top: 1px solid dimgray;
    padding-bottom: 3em;
    padding-left: 1em;

    color: white;

    a{
        :hover{
            color: ${theme.secondary}
        }
    }

    @media (max-width: 648px) {
        display: block;
    }

`