import styled from 'styled-components'
import { currentTheme, testTheme, theme } from '../../Styles'

export const NewNavbar = styled.div`
    width: 100%;
    max-width: 100%;
    height: 80px; 
    bottom: 0px;
    display: flex;
    color: white;
    text-align: center;
    font-size: 12px;
    top: 0px;
    background-color: ${theme.mainDarker};
    position: fixed;
    z-index: 15000;
`

export const NewUINavLinkCont = styled.button`
    display: inline-block;
    vertical-align: middle;
    width: 100px;
    max-width: 100px;
    font-size: 14px;
    text-align: center;
    font-weight: ${props => props.navLocation == props.currentNavbarLocation ? 900 : 200 };
    color: ${props => props.navLocation == props.currentNavbarLocation ? theme.secondary : theme.textSecondary};
    border-bottom: ${props => props.navLocation == props.currentNavbarLocation && "3px solid " + theme.secondary};
    margin-top: 5px;

    :hover{
        border-bottom: 3px solid ${theme.textMain};
        color: ${theme.textMain};
    }

    @media (max-width: 900px) {
       display: none;
   }
`

export const NewUINavLink = styled.a`
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    vertical-align: middle;
    height: 100%;
    width: 100%;
   
`

export const SidebarNavLink = styled.a`
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    vertical-align: middle;
    height: 40px;
    width: 200px;
    padding-left: 15px;
    border-radius: 10px;

    :hover{
        background-color: #10182c;
        color: ${theme.textMain};
    }
   
`


export const NewUIMobileNavLinkCont = styled.button`
    display: inline-block;
    vertical-align: middle;
    width: 49%;
    max-width: 49%;
    height: 49%;
    max-height: 49%;
    font-size: 14px;
    text-align: center;
    font-weight: ${props => props.navLocation == props.currentNavbarLocation ? 900 : 200 };
    color: ${props => props.navLocation == props.currentNavbarLocation ? testTheme.secondary : testTheme.textSecondary};
    border-bottom: ${props => props.navLocation == props.currentNavbarLocation && "3px solid " + testTheme.secondary};

    :hover{
        border-bottom: 3px solid ${testTheme.secondaryHover};
        color: ${testTheme.secondaryHover};
    }

    @media (min-width: 749px) {
       
       display: none;
   }
`

export const ExpandMobileNav = styled.div`
    width: 300px;
    max-width: 80%;
    height: 150px;
    position: fixed;
    left: 10px;
    top: 90px;
    background-color: ${testTheme.mainDark};
    border: 2px solid #b9b9b9;
    border-radius: 10px;
    color: ${testTheme.textSecondary};
    z-index: 10000;
    font-weight: 900;
    padding-left: 1%;
    padding-top: 0.5%;

    @media (min-width: 749px) {
       
       display: none;
   }    

`

export const MobileNavLine = styled.div`
    display: block;
    width: 24px;
    max-width: 24px;
    height: 4px; 
    background-color: white;
    opacity: 100%;
    margin-left: auto;
    margin-top: 6px;
    margin-right: auto;
    
    @media (min-width: 991px) {
        display:none;
    }
`