import React, { useEffect } from "react";
import mindmaster_logo from "../data/mindmaster_logo.png";
import { useStateContext } from "../contexts/ContextProvider";
import { UALProvider } from "ual-reactjs-renderer";
import { Name } from "eos-common";
import {
  MindmasterLogo,
  MindmasterWrapper,
  MobileNavCont,
  Modal,
  ModalContent,
  ModalOverlay,
  NewAnchorButton,
  NewCloudButton,
  Spinner,
  SpinnerBlue,
  SpinnerGreen,
  SpinnerRed,
  WaxDAOText,
  CloseWindow,
  MobileModalOverlay,
  MindmasterMobileWrapper,
  MindmasterMobileLogo,
  WalletIconButtonWrapper,
} from "./LeftDiv";
import {
  wax,
  anchor,
  myChain,
  currentWebsiteURL,
  currentUsername,
  currentWalletProvider,
  wombat,
  mainnetAtomicApiList,
} from "../data/config";
import {
  NewNavbar,
  NewUINavLinkCont,
  NewUINavLink,
  MobileNavLine,
  SidebarNavLink,
} from "../data/css/NavbarStyles";
import {
  CloseSidebarButton,
  LargeScreenNavDropDown,
  MobileSidebar,
  SidebarLinkContainer,
  SidebarLinksGap,
  SidebarOverlay,
  SidebarScrollWrapper,
} from "../data/css/SidebarStyles";
import { theme, ViewPoolButton } from "../Styles";

const Navbar = () => {
  const {
    setScreenSize,
    enterModalText,
    setEnterModalText,
    loadingDisplay,
    enterModalDisplay,
    setEnterModalDisplay,
    mobileNavDisplay,
    setMobileNavDisplay,
    setWalletSidebarIsOpen,
    currentNavbarLocation,
    mobileNavIsOpen,
    setMobileNavIsOpen,

  } = useStateContext();

  useEffect(() => {
    setMobileNavDisplay("hidden");

  }, []);


  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="">

        <ModalOverlay className={enterModalDisplay} />
        <MobileModalOverlay className={mobileNavDisplay} />
        <Modal className={enterModalDisplay}>
          <ModalContent>{enterModalText}</ModalContent>

          <Spinner style={{ display: loadingDisplay }}>
            <SpinnerRed />
            <SpinnerBlue />
            <SpinnerGreen />
          </Spinner>

          <br />
          <CloseWindow onClick={() => setEnterModalDisplay("hidden")}>
            Close Window
          </CloseWindow>
        </Modal>

        <NewNavbar>
          <MindmasterWrapper href={`${currentWebsiteURL}`}>
            <MindmasterLogo src={mindmaster_logo} />{" "}
            <WaxDAOText>WaxDAO BP</WaxDAOText>
          </MindmasterWrapper>

          <MindmasterMobileWrapper href={`${currentWebsiteURL}`}>
            <MindmasterMobileLogo src={mindmaster_logo} />
          </MindmasterMobileWrapper>

          



          <NewUINavLinkCont
            navLocation={"Home"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <NewUINavLink href={`${currentWebsiteURL}`}>
              Home
            </NewUINavLink>
          </NewUINavLinkCont>

          <NewUINavLinkCont
            navLocation={"Tools"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <NewUINavLink href={`${currentWebsiteURL}/tools`}>
              Tools
            </NewUINavLink>
          </NewUINavLinkCont>

          <NewUINavLinkCont
            navLocation={"Infra"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <NewUINavLink href={`${currentWebsiteURL}/infra`}>
              Infra
            </NewUINavLink>
          </NewUINavLinkCont>

          <NewUINavLinkCont
            navLocation={"Team"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <NewUINavLink href={`${currentWebsiteURL}/team`}>
              Team
            </NewUINavLink>
          </NewUINavLinkCont>

          <NewUINavLinkCont
            navLocation={"bp"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <NewUINavLink href={`${currentWebsiteURL}/bp.json`}>
              bp.json
            </NewUINavLink>
          </NewUINavLinkCont>

          {/* MOBILE */}

          <MobileNavCont
            onClick={() => {
              setWalletSidebarIsOpen(false);
              setMobileNavIsOpen((prev) => !prev);
            }}
          >
            <MobileNavLine />
            <MobileNavLine />
            <MobileNavLine />
          </MobileNavCont>
        </NewNavbar>

        {/* MOBILE EXTENDED */}

        <SidebarOverlay className={!mobileNavIsOpen && "hidden"} />
        <MobileSidebar isOpen={mobileNavIsOpen}>
          <SidebarLinksGap>
            <CloseSidebarButton onClick={() => setMobileNavIsOpen(false)}>
              X
            </CloseSidebarButton>
          </SidebarLinksGap>

          <SidebarScrollWrapper>
          <SidebarLinkContainer
            navLocation={"Home"}
            currentNavbarLocation={currentNavbarLocation}
          >
           
          </SidebarLinkContainer>

          
          
          <span style={{color: theme.textMain, paddingLeft: "35px", fontSize: "10px"}}>NAVIGATION</span><br/>
          <SidebarLinkContainer
            navLocation={"Home"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <SidebarNavLink href={`${currentWebsiteURL}`}>
              Home
            </SidebarNavLink>
          </SidebarLinkContainer>

          <SidebarLinkContainer
            navLocation={"Tools"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <SidebarNavLink href={`${currentWebsiteURL}/tools`}>
              Tools
            </SidebarNavLink>
          </SidebarLinkContainer>

          <SidebarLinkContainer
            navLocation={"Infra"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <SidebarNavLink href={`${currentWebsiteURL}/infra`}>
              Infra
            </SidebarNavLink>
          </SidebarLinkContainer>

          <SidebarLinkContainer
            navLocation={"Team"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <SidebarNavLink href={`${currentWebsiteURL}/team`}>
              Team
            </SidebarNavLink>
          </SidebarLinkContainer>

          <SidebarLinkContainer
            navLocation={"bp"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <SidebarNavLink href={`${currentWebsiteURL}/bp.json`}>
            bp.json
            </SidebarNavLink>
          </SidebarLinkContainer>

          
          </SidebarScrollWrapper>
        </MobileSidebar>




        {/* Large Screen Dropdown On Right Side */}

        <SidebarOverlay className={!mobileNavIsOpen && "hidden"} />
        <LargeScreenNavDropDown isOpen={mobileNavIsOpen}>
          <SidebarLinksGap>
          <CloseSidebarButton onClick={() => setMobileNavIsOpen(false)}>
              X
            </CloseSidebarButton>
          </SidebarLinksGap>

          <SidebarScrollWrapper>
          <SidebarLinkContainer
            navLocation={"Home"}
            currentNavbarLocation={currentNavbarLocation}
          >
           
           
          </SidebarLinkContainer>

          
          <span style={{color: theme.textMain, paddingLeft: "35px", fontSize: "10px"}}>TOOLS</span><br/>
          <SidebarLinkContainer
            navLocation={"Pack Creator"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <SidebarNavLink href={`${currentWebsiteURL}/create-pack`}>
              Pack Creator
            </SidebarNavLink>
          </SidebarLinkContainer>

    

          </SidebarScrollWrapper>
        </LargeScreenNavDropDown>


    </div>
  );
};

export default Navbar;
