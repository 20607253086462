import React, { useEffect, useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { UALProvider } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { TrustedByTitle, CallToAction, CallToActionCont, StatsCont, SingleStat, SingleStatHeader, SingleStatDetail, FeaturesCont, SingleFeature, TrustedBySmall, BenefitsCont, SingleBenefitPic, SingleBenefitText, SingleBenefitTitle, SingleBenefitDescription, SocialProofCont, SocialProofTitle, SocialProofLogoCont, LastCallToActionCont, LastCallToActionTitle, HomepageFAQCont, HomepageFAQTitle, HomepageFAQDescription, HomepageQuestion, HomepageQuestionPlusSign, HomepageFAQAnswer, FAQHref, MainContainer } from '../components/LeftDiv';
import NumberFormat from 'react-number-format';

import { anchor, wax, myChain, atomicApiList, hyperionApiList, mainnetApiList, mainnetAtomicApiList, currentWebsiteURL, currentAtomicApiList, currentApiList, localServerURL, getStatsEndpoint, wombat } from '../data/config';
import { HomePageCont, HeroLeftSide, HeroRightSide, HeroLargeText, HeroMediumText, CallToActionHrefHomepage, CallToActionContent, HeroAd, HeroAdImage, HeroAdMessage, WojakCallToAction, SocialProofSingleLogo } from '../data/css/HomePageStyles';
import { MilestoneContainer, MilestoneDescription, NewListingBody, NewListingCont, testTheme, theme } from '../Styles';



const Infra = () => {


  return (
  <div id="seo">
    <Helmet>
    <title>Infra - WaxDAO BP</title>
    <meta name="description" content="A list of the infrastructure that WaxDAO BP offers to the WAX blockchain" />
    <link rel="canonical" href={`${currentWebsiteURL}/infra`} />
    </Helmet>

  

    <NewListingCont>
        <NewListingBody>
            <MilestoneContainer>
                <h2>Testnet Producer</h2>
                <br/>
                <MilestoneDescription>
                • Intel Xeon-E 2288G - 3.7 GHz CPU<br/>
                • 128 GB RAM<br/>
                • 2x 960 GB NVMe SSD
                </MilestoneDescription>
            </MilestoneContainer>

            <MilestoneContainer>
                <h2>Testnet API</h2>
                <br/>
                <MilestoneDescription>
                • Intel Xeon-E 2288G - 3.7 GHz CPU<br/>
                • 128 GB RAM<br/>
                • 2x 960 GB NVMe SSD                    
                </MilestoneDescription>
            </MilestoneContainer>  

            <MilestoneContainer>
                <h2>Testnet Peer To Peer</h2>
                <br/>
                <MilestoneDescription>
                • Intel Xeon-E 2288G - 3.7 GHz CPU<br/>
                • 128 GB RAM<br/>
                • 2x 960 GB NVMe SSD                    
                </MilestoneDescription>
            </MilestoneContainer> 

            <MilestoneContainer>
                <h2>Testnet History</h2>
                <br/>
                <MilestoneDescription>
                • Intel Xeon-E 2288G - 3.7 GHz CPU<br/>
                • 128 GB RAM<br/>
                • 2x 960 GB NVMe SSD                    
                </MilestoneDescription>
            </MilestoneContainer>             

        </NewListingBody>
    </NewListingCont>




    <br/><br/><br/>
    </div>
  )
}

export default Infra